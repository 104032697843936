import { useNavigate } from 'react-router-dom';
import { ProjectContext } from '../../ProjectContext';
import { useContext } from 'react';

const DashBoardHeader = () => {
    const { disconnectNow } = useContext(ProjectContext);

    var navigate = useNavigate();

    const logout = () => {
        disconnectNow();
        navigate('/')
    }

    return (
        <div>
            <div className="overlay" />
            <div className="dashboard__header">
                {/* <a href="#" className="user">
                    <img src="assets/images/avt/admin.jpg" alt="noovko" />
                </a> */}
                {/* <a href="#" className="btn-action-outline style-5 " onClick={() => logout()}><span className="icon-wallet" /><span>Logout</span></a> */}

                <div className='animated-border-button rounded-pill'>
                    <a className="btn-action-outl inestyle-5" onClick={() => logout()}><span className="icon-wallet" /><span> Logout</span></a>
                </div>

            </div>
        </div>
    );
}

export default DashBoardHeader;