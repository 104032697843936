import React from 'react'
import { ProjectContext } from '../../ProjectContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom/dist';

export default function Header() {
    const { account, connectMetamask, copyaddress, dbuser, formatAddress } = useContext(ProjectContext);

    return (
        <>
            <header id="header_main" className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="header__body">
                                <div className="header__logo">
                                    <a href="/">
                                        <img id="site-logo" src="assets/images/logo/logo.png" alt="Noovko" width={165} height={40} data-retina="assets/images/logo/logo-main@x2.png" data-width={165} data-height={40} />
                                    </a>
                                </div>
                                <div className="header__right">
                                    <nav id="main-nav" className="main-nav">
                                        <ul id="menu-primary-menu" className="menu">
                                            <li className="menu-item">
                                                <a href="#">About</a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="#">Tokenomics</a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="">Roadmap</a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="#">Whitepaper</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="group-button">
                                        {account? 
                                        <Link to="/dashboard" className="btn-action-outline style-1">
                                            <span>{formatAddress(account)}</span>
                                        </Link>:
                                        <a href="#" className="btn-action-outline style-1" onClick={() => connectMetamask()}>
                                            <span>Connect Wallet</span>
                                        </a>}
                                    </div>
                                    <div className="mobile-button"><span /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}
