import React from 'react'
import Marquee from "react-fast-marquee";

export default function Partners() {
    return (
        <>
            <section className="partner style-3" id="partner">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-text center">
                                <h2 className="heading">
                                    Partners
                                </h2>
                                <p className="fs-20 mt-16">We have wide range of partners in Noovko ecosystem that helps us grow further.
                                </p>
                            </div>
                            <div className="partner__main">
                                <Marquee>

                                    <div className="image m-3">
                                        <img src="assets/images/trust_wallet.png" alt="ICOLand" />
                                    </div>

                                    <div className="image m-3">
                                        <img src="assets/images/yahoo.png" alt="ICOLand" />
                                    </div>

                                    <div className="image m-3">
                                        <img src="assets/images/be_in_crypto.png" alt="ICOLand" />
                                    </div>

                                    <div className="image m-3">
                                        <img src="assets/images/blockworks.png" alt="ICOLand" />
                                    </div>

                                    <div className="image m-3">
                                        <img src="assets/images/cmc.png" alt="ICOLand" />
                                    </div>
                                    <div className="image m-3">
                                        <img src="assets/images/coin_gecko.png" alt="ICOLand" />
                                    </div>

                                    <div className="image m-3">
                                        <img src="assets/images/coin_tracking.png" alt="ICOLand" />
                                    </div>

                                    <div className="image m-3">
                                        <img src="assets/images/dropstab.png" alt="ICOLand" />
                                    </div>

                                    <div className="image m-3">
                                        <img src="assets/images/market_watch.png" alt="ICOLand" />
                                    </div>

                                    <div className="image m-3">
                                        <img src="assets/images/metamask.png" alt="ICOLand" />
                                    </div>
                                </Marquee>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
