import React, { useContext, useState } from 'react'
import { ProjectContext } from '../../ProjectContext';
import { Link } from 'react-router-dom/dist';

export default function Sidebar() {
    const today = new Date();
    const year = today.getFullYear();
    const { showmenu, setshowmenu } = useContext(ProjectContext)
    const toggleClass = () => {
        setshowmenu(!showmenu);
    };
    return (
        <>
            <div className={!showmenu ? "btn click" : "btn"} onClick={toggleClass}>
                <span className="fas fa-bars" />
            </div>
            <nav className={!showmenu ? "dashboard__sidebar show" : "dashboard__sidebar"}>
                <div className="sidebar__logo">
                    <Link to="/"><img src="assets/images/logo/logo.png" alt="noovko" /></Link>
                </div>
                <ul className="sidebar__menu">
                    <li className="active"><Link to="/dashboard"><img src='./assets/images/nav/dashboard.png' className="icon-token" />&nbsp;Dashboard</Link></li>
                    <li><Link to="/staking"><img src='./assets/images/nav/staking.png' className="icon-token" />&nbsp;Staking</Link></li>
                    <li><Link to="/team"><img src='./assets/images/nav/team.png' className="icon-token" />&nbsp;Team</Link></li>
                    <li><Link to="/staking-reward"><img src='./assets/images/nav/staking_reward.png' className="icon-token" />&nbsp;Staking Reward</Link></li>
                    <li><Link to="/direct-reward"><img src='./assets/images/nav/direct_reward.png' className="icon-token" />&nbsp;Direct Reward</Link></li>
                    <li><Link to="/generator-reward"><img src='./assets/images/nav/generator_reward.png' className="icon-token" />&nbsp;Generator Reward</Link></li>
                    <li><Link to="/rank-reward"><img src='./assets/images/nav/rank_reward.png' className="icon-token" />&nbsp;Rank Reward</Link></li>
                    <li><Link to="/royalty-reward"><img src='./assets/images/nav/roylaty_reward.png' className="icon-token" />&nbsp;Royalty Reward</Link></li>
                    <li><Link to="/global-reward"><img src='./assets/images/nav/noovko_reward.png' className="icon-token" />&nbsp;Noovko Reward</Link></li>
                    <li><Link to="/promoters-reward"><img src='./assets/images/nav/promoters_reward.png' className="icon-token" />&nbsp;Promoters Reward</Link></li>
                    {/* <li><Link to="/booster-reward"><img src='./assets/images/nav/rank_reward.png' className="icon-token" />&nbsp;Booster Reward</Link></li> */}
                    <li><Link to="/withdrawal"><img src='./assets/images/nav/withdrawal.png' className="icon-token" />&nbsp;Withdrawal</Link></li>
                    <li><Link to="/team"><img src='./assets/images/nav/presentation.png' className="icon-token" />&nbsp;Presentation</Link></li>
                    <li><Link to="/unstake"><img src='./assets/images/nav/unstake.png' className="icon-token" />&nbsp;Unstake</Link></li>
                </ul>
                <div className="bottom">
                    <ul className="list-social">
                        <li><span className="icon-twitter" /></li>
                        <li><span className="icon-facebook" /></li>
                        <li><span className="icon-place" /></li>
                        <li><span className="icon-youtobe" /></li>
                    </ul>
                    <p className="fs-13">© {year}. All rights reserved by Noovko</p>
                </div>
            </nav>
        </>
    )
}
