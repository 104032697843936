import React, { useContext, useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { ProjectContext } from '../../ProjectContext'
import DashBoardHeader from './DashBoardHeader'
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom/dist'
export default function Stake() {
    const { link_ref_address } = useParams();

    const { showmenu, setshowmenu, formatAddress,
        copyaddress,
        web3,
        account,
        balance,
        rate,
        smuser,
        toastSuccess,
        library,
        dbuser,
        tokenrate,
        stakcontract,
        toastError,
        getudata,
        settings,
        esigner,
        ethers,
        getuserDB,
        connectMetamask,
    } = useContext(ProjectContext)
    const [amount, setamount] = useState(0);
    const [maticfee, setmaticfee] = useState(0);
    const [calcstakingfee, setcalcstakingfee] = useState(0);
    const [maticfee$, setmaticfee$] = useState(0);
    const [total_tokens, settotal_tokens] = useState(0);
    const [trdone, settrdone] = useState(false);
    const [hash, sethash] = useState("");
    const [loader, setloader] = useState(false);
    const [referral, setreferral] = useState("");

    var navigate = useNavigate();

    const onDeposit = async () => {
        settrdone(true);

        sethash("");
        if (!account) {
            connectMetamask();
            toastError("Connect first");
            settrdone(false);
            return;
        }
        if (!amount || amount < 0) {
            toastError("Enter amount");
            settrdone(false);
            return;
        }
        if (amount < 15 || amount > 10000) {
            toastError("Min $15 and Max $10000 required");
            settrdone(false);
            return;
        }
        // if (Number(balance) < Number(total_tokens)) {
        //   toastError("Insufficient balance");
        //   return;
        // }
        console.log("Reee", referral);
        if (!referral) {
            toastError("Enter Referral Address");
            settrdone(false);
            return;
        }
        var data = await getudata(referral);
        if (data.data.error) {
            toastError("Invalid Referral Address");
            settrdone(false);
            return "";
        }
        if (dbuser !== null && dbuser.last_staking > amount) {
            toastError(`Stake ${dbuser.last_staking} or more`);
            settrdone(false);
            return;
        }
        let contract = new library.Contract(
            settings.contract,
            JSON.parse(settings.contract_abi),
            esigner
        );
        let token_contract = new library.Contract(
            settings.token,
            JSON.parse(settings.token_abi),
            esigner
        );
        const allowance = await token_contract.allowance(
            account,
            settings.contract
        );

        async function main() {
            // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
            //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
            //   console.log("gasghd ",gsl,await library.eth.getGasPrice()
            //   );
            try {
                var stk = await contract.stake(
                    ethers.utils.parseEther(`${amount}`),
                    referral,
                    { value: `${Number(calcstakingfee) + 1100000000}` }
                );
                // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

                const receipt = await stk.wait();
                sethash(receipt.transactionHash);
                // sethash(randomHash);
                toastSuccess("Transaction done");
                await axios
                    .post(process.env.REACT_APP_BACKEND_LINK, {
                        method: "transaction",
                        submethod: "insert",
                        key: process.env.REACT_APP_KEY,
                        address: account,
                        referral_address: referral,
                        hash: receipt.transactionHash,
                        // hash: randomHash,
                        amount: amount,
                        token: total_tokens,
                        matic: maticfee,
                        matic_rate: rate,
                        token_rate: tokenrate,
                        income_capability: 3,
                    })
                    .then((res) => {
                        // var dedata = decryptData(res.data);
                        if (res.data.error) {
                            return "";
                        }
                    });
                getuserDB();
                settrdone(false);
                navigate("/dashboard");

            } catch (error) {
                console.log("Error", error);
                toastError("Transaction declined");
                settrdone(false);
                return;
            }

        }
        // if (Number(library.utils.fromWei(allowance, "ether")) < total_tokens) {
        if (Number(ethers.utils.formatEther(allowance)) < total_tokens) {
            // if (false) {
            try {
                var approv = await token_contract.approve(
                    settings.contract,
                    ethers.utils.parseEther("100000000")
                );
                const receipt = await approv.wait();
                console.log(receipt);
                toastSuccess("Token Approved");
                main();
            } catch (error) {
                console.log("error", error);
                toastError("Token not approved");
                settrdone(false);
                return;
            }
        } else {
            main();
        }
    };
    const setvaluess = async () => {
        if (!stakcontract) {
            return;
        }
        // const calcstakingfeed = '5000000000000000000';
        const calcstakingfeed = await stakcontract.calcstakingfee(
            ethers.utils.parseEther(`${amount}`)
        );
        const defaultfee = await stakcontract.defaultfee();
        // console.log("calcstakingfeed",Number(defaultfee));
        setcalcstakingfee(calcstakingfeed)
        setmaticfee(Number(ethers.utils.formatUnits(calcstakingfeed, 18)));
        setmaticfee$(Number(ethers.utils.formatUnits(defaultfee, 18)));
        // setincapper(1);
    };
    useEffect(() => {
        if (dbuser !== null) {
          if (dbuser.referral !== null) {
            setreferral(dbuser.ref_address);
          }
        } else {
          setreferral(link_ref_address);
        }
      }, [link_ref_address, dbuser, account]);
    
    useEffect(() => {
        setvaluess();
    }, [amount, stakcontract]);

    useEffect(() => {
        settotal_tokens(amount / tokenrate);

    }, [amount, tokenrate]);

    return (
        <>
            <div className={!showmenu ? 'dashboard page-loaded show' : 'dashboard page-loaded'}>
                <Sidebar />

                <div className="dashboard__content">
                    <DashBoardHeader />
                    <section className="sign-in">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sign-in__top center">
                                        <a href="index.html">
                                            <img id="site-logo" src="/assets/images/logo/logo.png" alt="Noovko" width={165} height={40} data-retina="assets/images/logo/logo-main@x2.png" data-width={165} data-height={40} />
                                        </a>
                                    </div>
                                    <div className="sign-in__main">
                                        <div className="top center">
                                            <h3 className="title text-white">Stake</h3>
                                            <p className="fs-17 text-white">Min: $15 Max $10000</p>
                                        </div>
                                        <form>
                                            <div className="form-group">
                                                <label className='text-white'>Referral Address </label>
                                                {dbuser !== null ? (
                                                    dbuser.ref_address !== null ? (
                                                        <input
                                                            className="nftmax__item-input"
                                                            type="text"
                                                            value={referral}
                                                        />
                                                    ) : (
                                                        <input
                                                            className="nftmax__item-input"
                                                            type="text"
                                                            placeholder="Enter Referral Address"
                                                            value={referral}
                                                            onChange={(e) => { setreferral(e.target.value); }
                                                            }
                                                        />
                                                    )
                                                ) : (
                                                    <input
                                                        className="nftmax__item-input"
                                                        type="text"
                                                        placeholder="Enter Referral Address"
                                                        value={referral}
                                                        onChange={(e) => { setreferral(e.target.value); }
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label className='text-white' htmlFor="amountnnvvk">Amount</label>
                                                <input type="text" className="form-control" id="amountnnvvk" value={amount}
                                                    onChange={(e) =>
                                                        setamount(e.target.value)
                                                    } placeholder="0" />
                                            </div>

                                            <div className="form-check">
                                                <div className="left">
                                                    <label className="form-check-label text-white" htmlFor="exampleCheck1">Stake</label>
                                                </div>
                                                <p className='text-white'>{total_tokens}{" "}
                                                    {process.env.REACT_APP_TICKER}</p>
                                            </div>
                                            <div className="form-check">
                                                <div className="left">
                                                    <label className="form-check-label text-white" htmlFor="exampleCheck1">Token Rate</label>
                                                </div>
                                                <p className='text-white'>${tokenrate}</p>
                                            </div>
                                            <div className="form-check">
                                                <div className="left">
                                                    <label className="form-check-label text-white" htmlFor="exampleCheck1">Fee</label>
                                                </div>
                                                <p className='text-white'>{Number(maticfee).toFixed(2)} BNB ({maticfee$} %)</p>
                                            </div>

                                            {hash ? (
                                                <div className="p-3 text-light text-center">
                                                    Hash :{" "}
                                                    <a
                                                        href={`${process.env.REACT_APP_EXPLORER}tx/${hash}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        #: {formatAddress(hash)}
                                                    </a>
                                                </div>
                                            ) : (
                                                ""
                                            )}{trdone ? (
                                                <div className='text-center'>
                                                    <img
                                                        className="border-0"
                                                        src="/assets/images/loader.gif"
                                                        alt="loading"
                                                        height={100}
                                                        width={100}
                                                    />
                                                </div>
                                            ) : (
                                                <button type="button" className="btn-action style-1" onClick={() => onDeposit()}><span>Stake</span> </button>
                                            )}
                                        </form>
                                    </div>
                                    {/* <p className="bottom">Dont have an account? <a href="sign-up.html">Sign up</a></p> */}
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>

        </>
    )
}
