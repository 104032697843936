import React from 'react'

export default function Faq() {
    return (
        <>
            <section className="section-faq style-2">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="faq__content">
                                <div className="block-text">
                                    <h3 className="heading">FAQs</h3>
                                    <p>You can join our community and ask any question! We will be happy to answer all your questions.</p>
                                </div>
                                <div className="flat-tabs">
                                    <ul className="menu-tab">
                                        <li className="active"><h6>Noovko Payments</h6></li>
                                        <li><h6>Noovko Token (NVK)</h6></li>
                                    </ul>
                                    <div className="content-tab">
                                        <div className="content-inner flat-accordion">
                                            <div className="flat-toggle">
                                                <h6 className="toggle-title">What is Noovko Payments?
                                                </h6>
                                                <p className="toggle-content">Noovko Payments is a platform for processing crypto-to-fiat payments.
                                                </p>
                                            </div>
                                            <div className="flat-toggle active">
                                                <h6 className="toggle-title active">How does it work?</h6>
                                                <p className="toggle-content">Noovko’s payment mechanism instantly converts cryptocurrency into fiat currency and uses it to pay at the payment window.
                                                </p>
                                            </div>
                                            <div className="flat-toggle">
                                                <h6 className="toggle-title">
                                                    What cryptocurrencies are supported?
                                                </h6>
                                                <p className="toggle-content">Noovko currently supports Noovko token (NVK) with plans to add more assets in future.
                                                </p>
                                            </div>
                                            <div className="flat-toggle">
                                                <h6 className="toggle-title">Where can I use Noovko payments?
                                                </h6>
                                                <p className="toggle-content">You can use Noovko at any of our partner sites.</p>
                                            </div>
                                        </div>
                                        <div className="content-inner flat-accordion">
                                            <div className="flat-toggle active">
                                                <h6 className="toggle-title active">What is NOOVKO (NVK) Token?</h6>
                                                <p className="toggle-content">NOOVKO (NVK) token is the native currency of Noovko payment platform.</p>
                                            </div>
                                            <div className="flat-toggle">
                                                <h6 className="toggle-title">How NOOVKO token functions?</h6>
                                                <p className="toggle-content">NOOVKO (NVK) is deployed on the BNB Chain and it can be transferred between any crypto wallets. </p>
                                            </div>
                                            <div className="flat-toggle">
                                                <h6 className="toggle-title">How to purchase NOOVKO token?</h6>
                                                <p className="toggle-content">NOOVKO (NVK) can be purchased from our partner exchanges or by joining Noovko’s disbursal plan.</p>
                                            </div>
                                            <div className="flat-toggle">
                                                <h6 className="toggle-title">What are the benefits of NOOVKO Token?
                                                </h6>
                                                <p className="toggle-content">NOOVKO (NVK) can be used to pay at any payment outlet by simply scanning the QR code or sending directly via Noovko application. It will automatically convert tokens in fiat and sellers will receive fiat in their account. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="faq__images">
                                <img src="assets/images/faqs.png" alt="ICOLand" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
