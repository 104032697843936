import React, { useContext, useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { ProjectContext } from '../../ProjectContext'
import DashBoardHeader from './DashBoardHeader'
import axios from "axios";


export default function Team() {
    const { showmenu, setshowmenu, account, copyaddress, formatAddress } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [level, setlevel] = useState(1);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "level",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                level: level,

                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);
    return (
        <>
            <div className={!showmenu ? 'dashboard page-loaded show' : 'dashboard page-loaded'}>
                <Sidebar />

                <div className="dashboard__content">
                    <DashBoardHeader />
                    <section className='pt-5'>
                        <h1>Team</h1>


                        <div className="row">
                            <div className="col-xl-5 col-md-12">
                                <div className="content-left">
                                    <form action="#" id="subscribe-form">
                                        <label className='text-white' htmlFor="amountnnvvk">Level</label>
                                        <input type="email" placeholder="Enter your email" value={level}
                                            onChange={(e) => setlevel(e.target.value)} id="amountnnvvk" width={"50px"} />
                                        <button className="btn-action style-2" type="submit" onClick={() => getData()} id="subscribe-button" style={{ top: "25px" }}> <span className="effect">Get</span></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* <form className='d-flex'>
                            <div className="form-group">

                                <input type="text" className="form-control" id="amountnnvvk" width={"50px"}
                                    value={level}
                                    onChange={(e) => setlevel(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <button type="button" className="btn-action style-7" onClick={() => getData()}><span>Get</span> </button>
                            </div>
                        </form> */}
                        <div className='table-main'>
                            <div className="tbl-header">
                                <table cellPadding={0} cellSpacing={0} border={0}>
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>Address</th>
                                            <th>Referral Address</th>
                                            <th>Team Business</th>
                                            <th>Staking</th>
                                            <th>Datetime</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div className="tbl-content">
                                <table cellPadding={0} cellSpacing={0} border={0}>
                                    <tr className="text-center">
                                        {isLoading ? "Data is loading" : ""}
                                    </tr>
                                    {!isLoading ? (
                                        !data.length ? (
                                            <tr className="text-center">
                                                <td className="text-center" colSpan={7}>
                                                    <span className="w-100">No data found</span>
                                                </td>
                                            </tr>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}
                                    <tbody>
                                        {data.map((ele, i) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {i + 1}
                                                    </td>
                                                    <td>
                                                        <span onClick={() =>
                                                            copyaddress(ele.address)
                                                        }>
                                                            {formatAddress(ele.address)}  <i className="fa fa-copy"></i>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span onClick={() =>
                                                            copyaddress(ele.ref_address)
                                                        }>
                                                            {formatAddress(ele.ref_address)}  <i className="fa fa-copy"></i>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        $ {ele.team_business} ({ele.team})
                                                    </td>
                                                    <td>
                                                        $ {ele.t_staking}
                                                    </td>
                                                    <td >
                                                        {ele.createdAt}
                                                    </td>
                                                    <td>
                                                        {ele.status ? (
                                                            <div className="text-success">
                                                                Active
                                                            </div>
                                                        ) : (
                                                            <div className="text-danger">
                                                                Not Active
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center my-3'>
                            <ul className="pagination">
                                <li><a href="#">1</a></li>
                                <li className="active"><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li>
                                    <a href="#">
                                        <svg width={16} height={14} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.33203 7H14.6654" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8.83203 1.16699L14.6654 7.00033L8.83203 12.8337" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </section>

                </div>
            </div>
        </>
    )
}
