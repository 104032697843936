import React from 'react'
import Header from '../Comman/Header'
import Hero from './Hero'
import Footer from '../Comman/Footer'
import Features from './Features'
import Roadmap from './Roadmap'
import Creation from './Creation'
import Document from './Document'
import About from './About'
import Faq from './Faq'
import JoinCommunity from './JoinCommunity'
import Partners from './Partners'
import Ecosystem from './Ecosystem'
import Functionality from './Functionality'
import Tokennomics from './Tokennomics'

export default function Index() {
    return (
        <>
            <Header />
            <Hero />
            <Features />
            <Functionality />
            <Tokennomics />
            <Roadmap />
            <Creation />
            <About />
            <Faq />
            <Partners />
            {/* <Ecosystem/> */}
            {/* <JoinCommunity/> */}
            <Footer />
        </>
    )
}
