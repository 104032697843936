import React, { useContext, useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { ProjectContext } from '../../ProjectContext'
import DashBoardHeader from './DashBoardHeader'
import axios from "axios";

export default function StakingReward() {
    const { showmenu, setshowmenu, account, copyaddress, formatAddress } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "sincome",
                submethod: "getbyid",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                console.log("res", res.data);
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);

    return (
        <>
            <div className={!showmenu ? 'dashboard page-loaded show' : 'dashboard page-loaded'}>
                <Sidebar />

                <div className="dashboard__content">
                    <DashBoardHeader />
                    <section className='pt-5'>
                        {/*for demo wrap*/}
                        <h1>Staking Reward</h1>
                        <div className='table-main'>
                            <div className="tbl-header">
                                <table cellPadding={0} cellSpacing={0} border={0}>
                                    <thead>
                                        <tr>
                                            <th> Sr</th>
                                            <th> Date</th>
                                            <th> Stake Amount</th>
                                            <th> Reward(%)</th>
                                            <th> Status</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div className="tbl-content">
                                <table cellPadding={0} cellSpacing={0} border={0}>
                                    <tr className="text-center">
                                        {isLoading ? "Data is loading" : ""}
                                    </tr>
                                    {!isLoading ? (
                                        !data.length ? (
                                            <tr className="text-center">
                                                <td className="text-center" colSpan={7}>
                                                    <span className="w-100">No data found</span>
                                                </td>
                                            </tr>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}
                                    <tbody>
                                        {data.map((ele, i) => {
                                            return (
                                                <tr>
                                                    <td >
                                                        {ele.id}
                                                    </td>
                                                    <td >
                                                        {ele.createdAt}
                                                    </td>
                                                    <td >
                                                        $ {ele.staking}
                                                    </td>
                                                    <td >
                                                        $ {ele.income} ({ele.income_per === 0.6 ? "🚀" : ""}{ele.income_per} %)
                                                    </td>
                                                    <td>
                                                        {ele.flushed ? "Flushed" : ""}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center my-3'>
                            <ul className="pagination">
                                <li><a href="#">1</a></li>
                                <li className="active"><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li>
                                    <a href="#">
                                        <svg width={16} height={14} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.33203 7H14.6654" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8.83203 1.16699L14.6654 7.00033L8.83203 12.8337" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </section>

                </div>
            </div>
        </>
    )
}
