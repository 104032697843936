import React from 'react'

export default function About() {
    return (
        <>
            <section className="about style-3">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-md-12">
                            <div className="about__video">
                                <img src="assets/images/noovko_gaming.png" width="100%" alt="ICOLand" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12 d-flex justify-content-end">
                            <div className="about__content text-end">
                                <h6 className="sub-title">Gaming</h6>
                                <h3 className="title">Noovko Gaming</h3>
                                <p className="text px-0">Noovko has integrated a native gaming application.
                                    It allows users to play online games and earn NOOVKO tokens in reward.</p>
                                <ul>
                                    <li>Blockchain-based Online Games</li>
                                    <li>Decentralized, Transparent & Fairplay</li>
                                    <li>Win Exclusive Rewards</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
