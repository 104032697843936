import React from 'react'

export default function Features() {
    return (
        <>
            <section className="about style-2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="about__content">
                                <div className="row">
                                    <div className="col-xl-5 col-md-12">
                                        <div className="block-text">
                                            <h3 className="title">What is Noovko?</h3>
                                            <p className="fs-20 mt-12">Noovko is a crypto payment platform that allows users to spend cryptocurrency at any business or retail outlet.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-xl-7 col-md-12">
                                        <div className="tf-box">
                                            <div className="box-icon">
                                                <img src="./assets/images/global_payment_methods.png" alt="" />
                                                <p>Global payment methods</p>
                                            </div>
                                            <div className="box-icon">
                                                <img src="./assets/images/scan_pay_any_currency.png" alt="" />
                                                <p>Scan & pay any currency</p>
                                            </div>
                                            <div className="box-icon">
                                                <img src="./assets/images/earn_rewards_in_noovko_token.png" alt="" />
                                                <p>Earn rewards in NOOVKO token</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
