import React from 'react'

export default function Creation() {
    return (
        <>
            <section className="about style-3">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-md-12">
                            <div className="about__content">
                                <h6 className="sub-title">Token
                                </h6>
                                <h3 className="title">NOOVKO Token</h3>
                                <p className="text">NOOVKO token (NVK) is the native token of Noovko payment platform where it is used to convert fiat into crypto and vice-versa. It can be purchased, claimed or earned by joining Noovko’s unique token disbursal plans.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="about__video"> 
                                <img src="assets/images/noovko_token.png" width="100%" alt="ICOLand" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
