import React from 'react'

export default function Functionality() {
    return (
        <>
            <section className="features" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-text center">
                                <h3 className="title">
                                    Key-features of Noovko Payments
                                </h3>
                                <p className="fs-17 mt-12">
                                    Noovko payments are transactions executed by converting crypto into any fiat currency at the time of payment processing.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-md-6">
                            <div className="choose__box" data-aos="fade-up" data-aos-duration={800}>
                                <div className="choose__box-icon center">
                                    <div className="icon">
                                        <span className="icon-mobile" />
                                    </div>
                                    <div className="content">
                                        <h5 className="h5">Safe & Secure
                                        </h5>
                                        <p className="mt-9">All payments are securely executed on blockchain</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="choose__box" data-aos="fade-up" data-aos-duration={1000}>
                                <div className="choose__box-icon center">
                                    <div className="icon">
                                        <span className="icon-startup" />
                                    </div>
                                    <div className="content">
                                        <h5 className="h5">
                                            Instant Conversion
                                        </h5>
                                        <p className="mt-9">Converting crypto into fiat instantly at payment window
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="choose__box" data-aos="fade-up" data-aos-duration={1200}>
                                <div className="choose__box-icon center">
                                    <div className="icon">
                                        <img src="./assets/images/foolproof.png" alt="" />
                                    </div>
                                    <div className="content">
                                        <h5 className="h5">Foolproof</h5>
                                        <p className="mt-9">All processes are executed on smart contract</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="choose__box" data-aos="fade-up" data-aos-duration={1400}>
                                <div className="choose__box-icon center">
                                    <div className="icon">
                                        <span className="icon-mobile" />
                                    </div>
                                    <div className="content">
                                        <h5 className="h5">Exclusive Rewards
                                        </h5>
                                        <p className="mt-9">Users can claim NOOVKO (NVK) token as payment rewards
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
