import React from 'react'

export default function Hero() {
    return (
        <>
            <section className="banner-top style-2">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-md-12">
                            <div className="banner-top__content">
                                <h2 className="title">The One-step
                                    <span> Crypto Payment</span> Solution.</h2>
                                <p className="desc">Noovko enables paying crypto anywhere by instant crypto-to-fiat conversion
                                </p>
                                <div className="button">
                                    <a href="https://noovko.gitbook.io/noovko-whitepaper" target='_blank' className="btn-action style-2"><span>Whitepaper </span></a>
                                </div>
                                <ul className="content-list">
                                    <li>
                                        <img src="./assets/images/payment_partners.png" alt="" />
                                        <h5>+10</h5>
                                        <p>Payment Partners</p>
                                    </li>
                                    <li>
                                        <img src="./assets/images/payments_processed.png" alt="" />
                                        <h5>+10,000</h5>
                                        <p>CryptoItem</p>
                                    </li>
                                    <li>
                                        <img src="./assets/images/cumulative_volume.png" alt="" />
                                        <h5>+120{"K"}</h5>
                                        <p>Cumulative Volume</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="banner-top__sale">
                                <img src="assets/images/hero.png" alt="ICOLand" />
                                {/* <div className="sale-content">
                                    <h6>Token sale End In!</h6>
                                    <span className="js-countdown" data-timer={1865550} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
