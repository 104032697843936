import React, { useContext, useState } from 'react'
import Sidebar from './Sidebar'
import { ProjectContext } from '../../ProjectContext'
import DashBoardHeader from './DashBoardHeader'

export default function Unstake() {
    const { showmenu, setshowmenu } = useContext(ProjectContext)

    return (
        <>
            <div className={!showmenu ? 'dashboard page-loaded show' : 'dashboard page-loaded'}>
                <Sidebar />

                <div className="dashboard__content">
                    <DashBoardHeader />
                    <section className='pt-5'>
                        {/*for demo wrap*/}
                        <div className='d-flex justify-content-between'>
                            <h1>Unstake</h1>
                            <div className="button">
                                <a className="btn-action style-2 mb-3" href="/stake">
                                    <span>Unstake</span>
                                </a>
                            </div>
                        </div>
                        <div className='table-main'>
                            <div className="tbl-header">
                                <table cellPadding={0} cellSpacing={0} border={0}>
                                    <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Company</th>
                                            <th>Price</th>
                                            <th>Change</th>
                                            <th>Change %</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div className="tbl-content">
                                <table cellPadding={0} cellSpacing={0} border={0}>
                                    <tbody>
                                        <tr>
                                            <td>AAC</td>
                                            <td>AUSTRALIAN COMPANY </td>
                                            <td>$1.38</td>
                                            <td>+2.01</td>
                                            <td>-0.36%</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center my-3'>
                            <ul className="pagination">
                                <li><a href="#">1</a></li>
                                <li className="active"><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li>
                                    <a href="#">
                                        <svg width={16} height={14} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.33203 7H14.6654" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8.83203 1.16699L14.6654 7.00033L8.83203 12.8337" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </section>

                </div>
            </div>
        </>
    )
}
