import React from 'react'

export default function Tokennomics() {
    return (
        <>
            <section className="about style-3">
                <div className="container">
                    <div className="about__content text-center mb-4">
                        <h6 className="sub-title">Tokenomics</h6>
                        <h3 className="title">Noovko Tokenomics</h3>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-md-12">
                            <div className="about__content">
                                <ul>
                                    <li><h5 className="title">Noovko Token</h5><p>Token Name</p> </li>
                                    <li><h5 className="title">NVK</h5><p>Ticker</p></li>
                                    <li><h5 className="title">15,000,000 (1.5 Cr)</h5><p>Total Supply</p></li>
                                    <li><h5 className="title">BNB Chain</h5><p>Chain</p> </li>
                                    <li><h5 className="title">18</h5><p>Decimal</p></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="about__video">
                                <img src="assets/images/tokenomics.png" width="100%" alt="ICOLand" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
