import React, { useContext } from 'react'
import Sidebar from './Sidebar'
import { ProjectContext } from '../../ProjectContext'
import { Link } from 'react-router-dom/dist'
import DashBoardHeader from './DashBoardHeader'
import CountdownTimer from '../Comman/CountdownTimer'

export default function Dashboard({ isClassToggled }) {
    const { showmenu, dbbalance, copyaddress, setshowmenu, switchNetwork, dbuser, account } = useContext(ProjectContext)
    return (
        <>
            <div className={!showmenu ? 'dashboard page-loaded show' : 'dashboard page-loaded'}>
                <Sidebar />

                <div className="dashboard__content">
                    <DashBoardHeader />
                    <section className="about style-2">
                        <div className="container">
                            <div className="row">
                                <div className='col-12'>
                                    {dbuser ? dbuser.rank_title === null ? "" : <h5 className="title blink_me">congratulations you achieved <span style={{ color: "#33c4e2" }}>{dbuser.rank_title}!</span></h5> : ''}
                                </div>
                                <div className="col-12">
                                    <div className="about__content">
                                        <div className="row">
                                            <div className="col-xl-4 col-md-12">
                                                <div className="block-text">
                                                    <h5 className="title">NOOVKO ACCOUNT</h5>
                                                    <p className="fs-15 mt-1 text-break" onClick={() => copyaddress(account)}>{account} <i className='fa fa-copy'></i></p>
                                                    <h6 className="title">Referral Address</h6>
                                                    <p className="fs-15 mt-1 text-break" onClick={() => copyaddress(dbuser !== null
                                                        ? dbuser.ref_address
                                                        : "-")}>{dbuser !== null
                                                            ? dbuser.ref_address
                                                            : "-"}{" "} <i className='fa fa-copy'></i></p>
                                                </div>
                                            </div>
                                            <div className="col-xl-8 col-md-12">
                                                <div className="tf-box">
                                                    <div className="box-icon">
                                                        <img src='./assets/images/icon/staking.png' width="50px" />
                                                        <h5>$ {dbuser ? dbuser.t_staking : 0}</h5>
                                                        <p>Staking</p>
                                                    </div>
                                                    <div className="box-icon">
                                                        <img src='./assets/images/icon/balance.png' width="50px" />
                                                        <h5>$ {dbbalance}</h5>
                                                        <p>Balance</p>
                                                    </div>
                                                    <div className="box-icon">
                                                        <img src='./assets/images/icon/rank.png' width="50px" />
                                                        <h5> {dbuser ? dbuser.rank_title === null ? "No Rank" : dbuser.rank_title : '-'}</h5>
                                                        <p>Rank</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {dbuser ?
                                <div className="row">
                                    <div className="col-12">
                                        <div className="about__content">
                                            <div className="row p-5">
                                                <div className="col-xl-12 col-md-12">
                                                    <div className="block-text text-center">
                                                        {dbuser ? <>
                                                            {dbuser.booster ?
                                                                <h3 className="title mt-0 mb-5">You are Eligible for Booster Reward</h3> :
                                                                <> <h3 className="title mt-0 mb-5">Booster Timer</h3>
                                                                    <h3 className="title"><CountdownTimer targetDate={dbuser ? new Date(dbuser.date72) : null} /></h3> </>}
                                                        </> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ""}
                            <div className="row">
                                <div className="col-12">
                                    <div className="about__content">
                                        <div className="row align-items-center justify-content-center p-5">
                                            <div className="col-xl-10 col-md-12">
                                                <div className='d-flex flex-wrap justify-content-around'>

                                                    <div className="button m-1">
                                                        <Link className="btn-action style-2" to="/stake">
                                                            <span>Stake Now</span>
                                                        </Link>
                                                    </div>
                                                    <div className="button m-1">

                                                        <a className="btn-action style-2" href="#">
                                                            <span>Buy NVK</span>
                                                        </a>
                                                    </div>
                                                    <div className="button m-1">
                                                        <a className="btn-action style-2" href="#" onClick={() => copyaddress(`${process.env.REACT_APP_LINK}stake/${account}`)}>
                                                            <span>Sponser Link</span>
                                                        </a>
                                                    </div>
                                                    <div className="button m-1">
                                                        <a className="btn-action style-2" href="#" onClick={() => switchNetwork(80002)}>
                                                            <span>Withdrawal</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="col-xl-6 col-md-12">
                                                <div className="tf-box">
                                                    <div className="box-icon">
                                                        <span className="icon-buy" />
                                                        <h5>$0</h5>
                                                        <p>Required Business</p>
                                                    </div>
                                                    <div className="box-icon">
                                                        <span className="icon-buy" />
                                                        <h5>0</h5>
                                                        <p>Required Team</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-12">
                                    <div className="about__content">
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12">
                                                <div className="tf-box">
                                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                                        <div className="box-icon box-icon2">
                                                            <img src='./assets/images/icon/r_leg.png' width="50px" />
                                                            <h5>$ {dbuser ? Number(dbuser.r_aleg).toFixed(2) : 0}</h5>
                                                            <p className="fs-17">R. Leg A</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                                        <div className="box-icon box-icon2">
                                                            <img src='./assets/images/icon/r_leg.png' width="50px" />
                                                            <h5>$ {dbuser ? Number(dbuser.r_bleg).toFixed(2) : 0}</h5>
                                                            <p className="fs-17">R. Leg B</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                                        <div className="box-icon box-icon2">
                                                            <img src='./assets/images/icon/r_leg.png' width="50px" />
                                                            <h5>$ {dbuser ? Number(dbuser.r_cleg).toFixed(2) : 0}</h5>
                                                            <p className="fs-17">R. Leg C</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                                        <div className="box-icon box-icon2">
                                                            <img src='./assets/images/icon/eligibility.png' width="50px" />
                                                            <h5>{dbuser ? Number(dbuser.income_cap) : 0}x</h5>
                                                            <p className="fs-17">Eligibility</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row mt-4">
                                <div className="tf-box d-flex flex-wrap justify-content-center">
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/staking_reward.png' width="50px" />
                                            <h5>$ {dbuser ? Number(dbuser.staking_rew).toFixed(2) : 0}</h5>
                                            <p className="fs-17">Staking Reward</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/global_team.png' width="50px" />
                                            <h5>{dbuser ? dbuser.global_team : 0}</h5>
                                            <p className="fs-17">Global Team</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/team.png' width="50px" />
                                            <h5> {dbuser ? dbuser.team : 0}</h5>
                                            <p className="fs-17">Team</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/direct_team.png' width="50px" />
                                            <h5> {dbuser ? dbuser.direct_team : 0}</h5>
                                            <p className="fs-17">Direct Team</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="tf-box d-flex flex-wrap justify-content-center">
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/direct_reward.png' width="50px" />
                                            <h5>$ {dbuser ? dbuser.level_inc : 0}</h5>
                                            <p className="fs-17">Direct Reward</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/generator_reward.png' width="50px" />
                                            <h5>$ {dbuser ? Number(dbuser.roiofroi_rew).toFixed(2) : 0}</h5>
                                            <p className="fs-17">Generator Reward</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/rank_reward.png' width="50px" />
                                            <h5>$ {dbuser ? Number(dbuser.rank_rew).toFixed(2) : 0}</h5>
                                            <p className="fs-17">Rank Reward</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/royalty_reward.png' width="50px" />
                                            <h5>$ {dbuser ? Number(dbuser.royalty_rew).toFixed(2) : 0}</h5>
                                            <p className="fs-17">Royalty Reward</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="tf-box d-flex flex-wrap justify-content-center">
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/noovko_reward.png' width="50px" />
                                            <h5>$ {dbuser ? Number(dbuser.global_rew).toFixed(2) : 0}</h5>
                                            <p className="fs-17">Noovko Reward</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/next_rank.png' width="50px" />
                                            <h5>{dbuser ? dbuser.next_rank : 0}</h5>
                                            <p className="fs-17">Next Rank</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/leg.png' width="50px" />
                                            <h5>$ {dbuser ? Number(dbuser.aleg).toFixed(2) : 0}</h5>
                                            <p className="fs-17">Leg A</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/leg.png' width="50px" />
                                            <h5>$ {dbuser ? Number(dbuser.bleg).toFixed(2) : 0}</h5>
                                            <p className="fs-17">Leg B</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="tf-box d-flex flex-wrap justify-content-center">
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/leg.png' width="50px" />
                                            <h5>$ {dbuser ? Number(dbuser.cleg).toFixed(2) : 0}</h5>
                                            <p className="fs-17">Leg C</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/club.png' width="50px" />
                                            <h5>{dbuser ? dbuser.club === null ? "-" : dbuser.club : "-"}</h5>
                                            <p className="fs-17">Club</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/total_business.png' width="50px" />
                                            <h5>$ {dbuser ? Number(dbuser.team_business).toFixed(2) : 0}</h5>
                                            <p className="fs-17">Total Business</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/withdrawal.png' width="50px" />
                                            <h5>$ {dbuser ? Number(dbuser.t_withdrawal).toFixed(2) : 0}</h5>
                                            <p className="fs-17">Withdrawal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="tf-box d-flex flex-wrap justify-content-start">
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-flex justify-content-center m-lg-0 m-2">
                                        <div className="box-icon box-icon2">
                                            <img src='./assets/images/icon/pending_withdrawal.png' width="50px" />
                                            <h5>$ 0</h5>
                                            <p className="fs-17">Pending Withdrawal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}
