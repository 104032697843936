import React from 'react'

export default function Footer() {
    const today = new Date();
    const year = today.getFullYear();
    return (
        <>
            <footer className="footer style-1">
                <div className="footer__bottom">
                    <div className="container">
                        <div className="row align-items-end pb-3">
                            <div className="col-md-6 col-12 d-flex justify-content-lg-start justify-content-center">
                                <p>© {year}. All rights reserved by Noovko</p>
                            </div>
                            <div className="col-xl-6 col-12 ">
                                <div className="content-right">
                                    {/* <p>Don’t hesitate to subscribe to latest news about ICo markets as well as crucial financial knowledge to become successful investors globally</p> */}
                                    <ul className="list-social flex-wrap justify-content-lg-end justify-content-center">
                                        <li><span className="icon-twitter" /></li>
                                        <li><span className="icon-facebook" /></li>
                                        <li><span className="icon-place" /></li>
                                        <li><span className="icon-youtobe" /></li>
                                        <li><span className="icon-tiktok" /></li>
                                        <li><span className="icon-reddit" /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}
